.notice-head{
    background-image: url("./bg-c.png");
    background-repeat: no-repeat;
    background-size: cover;

}
.notice {
   
    color: #050404;
    padding: 20px;
    text-align: center;
    display: flex;
    
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0 0 0 5%;
    

  }
  
  .notice h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .branch-container {
    margin: 20px;
    padding: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    /* display: inline-block; */
    text-align: left;
    width: 430px;
    min-height: 500px;
    height:fit-content;
  }
  
  .branch-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .branch-list {
    list-style-type: none;
    padding: 0;
  }
  
  .branch-list li {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .chota{
    min-height: 300px;
  }
  .notice-head h1,h2{
    /* margin-left: 30%; */
    text-align: center;
    text-decoration: underline;
    
  }
  .notice-head h2{
    padding-bottom: 1%;
  }
  .notice-head h1{
    padding-top: 1%;
  }
  @media (max-width: 500px) {
    .branch-title {
        font-size: 22px;
  }
  .branch-list li {
    font-size: 17px;
    margin-bottom: 8px;
    
  }
  .notice{
    padding-left: 2%;
  }
}
  
  