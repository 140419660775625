/* General Styles */

.gallery-containers {
  padding: 20px;
}

.batch-dropdowns {
  margin-bottom: 20px;
}

.image-gallerys {
  display: flex;
  flex-wrap: wrap;
}

/* Image Container Styles */

.image-containers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-containers img {
  width: calc(33.33% - 20px); /* Display 3 images in one row */
  margin: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}

.image-containers img:hover {
  transform: scale(1.1);
  background-color: #3498db; /* Change background color on hover to blue */
  transition: background-color 0.3s ease-in-out;
}

/* batch List and Item Styles */

.batch-lists {
  display: flex;
  flex-wrap: wrap;
}

.batch-items {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  width: 300px;
  transition: box-shadow 0.3s ease-in-out;
}

.batch-items:hover {
  box-shadow: 0 0 10px rgba(67, 92, 234, 0.2);
}

.batch-items img {
  width: 100%;
  max-height: 200px;
  object-fit: auto;
  border-radius: 5px;
}

.batch-items a {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

/* Media Queries */

@media (max-width: 768px) {
  .image-containers {
    width: calc(50% - 20px); /* 2 images per row on smaller screens */
  }

  .image-containers img {
    width: calc(50% - 20px);
  }

  .batch-items {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .image-containers {
    width: 100%; /* 1 image per row on mobile screens */
    flex-direction: column;
    align-items: center;
  }

  .image-containers img {
    width: 80%;
  }
}
