
.slider-container {
  position: relative;
  width: 100%;
  max-width: 100%; 
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #ddd; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.slide-image {
  width: 100%;
  height: 570px;
  object-fit: cover;
  display: block;
  transition: transform 0.5s ease-in-out;
}

  
  .writeup-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for better readability */
    color: #fff; /* Text color */
    padding: 10px;
  }
  
  .writeup-text {
    margin: 0; /* Remove default margin for the paragraph */
  }
  
  /* Add more styling as needed */

  @media (max-width: 400px) {
    .slider-container {
      position: relative;
      width: 100%;
      max-width: 1100px; 
      margin: 0 auto;
      overflow: hidden;
      border: 1px solid #ddd; 
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    }
    
    .slide-image {
      width: 100%;
      height: auto;
      display: block;
      transition: transform 0.5s ease-in-out;
    }
  
  }