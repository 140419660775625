.gallery-container {
    padding: 10px;
  }
  
  .batch-dropdown {
    margin-bottom: 20px;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-container img {
    width: calc(40% - 20px);
    margin: 10px;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .batch-list {
    display: flex;
    flex-wrap: wrap;
  }

  .batch-item {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    width: 300px;
  }

  .batch-item img {
    width: 100%;
    max-height:300px;
    object-fit:revert;
  }

  .batch-item a {
    display: block;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .image-container {
      width: calc(50% - 20px); /* 2 images per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .image-container {
      width: 100%; /* 1 image per row on mobile screens */
    }
  }



  

  
  @media (max-width: 768px) {
    .image-container img {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .image-container {
      flex-direction: column;
      align-items: center;
    }
  
    .image-container img {
      width: 80%;
    }
  }
    