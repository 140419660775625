@media (max-width: 400px) {
  .youtube-videos {
    display: flex;
    flex-direction: column;
   
  }
  .Youtube-cont{
    margin-left: 0;
    margin-right: 0;
  }
  .youtube-video-iframe{
    border:3px solid green;
    width: 100%;
    height: 300px;
  } 
  .youtube-video {
    margin-bottom: 10px; /* Add some spacing between videos */
  }

  .youtube-video-iframe {
    border: 3px solid green;
    width: 100%;
    height: 300px; /* Set a specific height for the YouTube video on mobile */
  }
}
  .youtube-video-iframe{
    border:3px solid green;
    width: 100%;
    height: 600px;
  }  
.Youtube-cont {
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 10%;
  margin-top: 3%;

}
  

  