/* AboutPage.css */

.about_section {
  padding: 50px 0;
  overflow: hidden; /* Prevent content overflow */
 
}

.container {
  max-width: 70%;
  overflow: hidden; /* Prevent content overflow */
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  flex: 0 0 33.333%; /* Three columns per row on larger screens */
  max-width: 33.333%;
  padding: 0 15px; /* Add horizontal spacing between columns */
}

.img-box img {
  width: 100%;
  border-radius: 12px;
}

.custom-boxes-container {
  padding: 50px 0;
  overflow: hidden; /* Prevent content overflow */
  padding-left: 7%;
  padding-right: 7%;
  
}
.custom-box:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgb(245, 198, 105);
}
.custom-box h2 {
  color: rgb(211, 140, 6);
}
.custom-box {
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.6s ease-in-out;
  border-radius: 15px;
}

/* Responsive Styles */
@media (max-width: 1140px) {
  .col-md-4 {
    flex: 0 0 50%; /* Two columns per row on smaller screens */
    max-width: 50%;
  }
  
  .custom-box {
    padding: 20px;
  }
}

@media (max-width: 760px) {
  .col-md-4 {
    flex: 0 0 100%; /* One column per row on even smaller screens */
    max-width: 90%;
  }
  .service_section.row{
    padding-left: 8%;
  }
  .container {
    max-width: 70%;
    overflow: hidden; /* Prevent content overflow */
  }
}
@media (max-width: 400px) {

  .container {
    max-width: 90%;
 
  }
}
