/* Add the existing styles for the Portfolio component */
.sub_page {
  padding: 20px;
}

.portfolio_section {
  /* background-color: #f4f4f4; */
  padding: 40px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.heading_container {
  margin-bottom: 40px;
}

.carousel-wrap {
  position: relative;
}

.filter_box {
  text-align: center;
}

.owl-filter-bar {
  display: flex;
  justify-content: center;
}

.item {
  margin: 0 10px;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  background-color: none;
}

.item.active {
  background-color: #fff;
  color: #333;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery_item {
  margin: 10px;
  border: 5px solid orange;
}

.gallery_item img {
  width: 300px;
  height: 300px;
  object-fit: auto;
}


.image-gallery-container {
  display: flex;
  flex-wrap: wrap;
}

.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modals_content {
  max-width: 90%;
  max-height: 90%;
  background: #fff; /* Add a white background for better visibility */
  overflow:scroll; /* Hide overflow content */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Add a subtle box shadow */
  border-radius: 8px; /* Add rounded corners */
}

.modals_content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px; /* Add rounded corners for the image */
}

@media (max-width: 400px) {
  .modals_content {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0; /* Remove border-radius for mobile view */
  }
}

